var site = site || {};

(function ($) {
  Drupal.behaviors.tabbedBlockV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;

      var $template = $('.js-tabbed-block-v1', context);
      var $blockTabs = $('.js-tabbed-block__tab', $template);

      $blockTabs.once().on('click', function () {
        var $this = $(this);
        var tabName = $this.data('tabName');
        var $index = $this.index();
        var $parent = $this.parent();
        var $content = $parent.siblings('.js-tabbed-block__content');
        var $blockContents = $('.js-tabbed-block__content-item', $content);

        $blockTabs.attr('aria-expanded', 'false');
        $this.attr('aria-expanded', 'true');
        $blockContents.attr('aria-hidden', 'true');
        $blockContents.eq($index).attr('aria-hidden', 'false');
        self.trackEvtLink(tabName);
      });
    },

    trackEvtLink: function (tabName) {
      site.track.evtLink({
        event_name: 'filters_and_sort_selection',
        event_category: 'filter & sort selection',
        event_action: 'filter',
        event_label: tabName
      });
    }
  };
})(jQuery);
